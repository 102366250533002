export const SERVICE_TYPES = {
	Hemstädning: "Hemstädning",
	Flyttstädning: "Flyttstädning",
	Storstädning: "Storstädning",
	Kontorsstädning: "Kontorsstädning",
};

export const URL_SERVICE = {
	Hemstädning: "hemstadning",
	Flyttstädning: "flyttstadning",
	Storstädning: "storstadning",
	Kontorsstädning: "kontorsstadning",
}
