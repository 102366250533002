import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { rootReducer } from '../../store/reducers';

const store = createStore(rootReducer);

const Wrapper = ({ children }) => {
	return (
		<Provider store={store}>
			{children}
		</Provider>
	);
};

export default Wrapper;